import React, { useState,useRef,useEffect } from "react"
import BackgroundVideo from "../videos/sunset_in_oia_seen_from_imerovigli.mp4"
import VideoPoster from "../images/rect.jpg"

import Layout from "../components/layout"
import PlayImage from "../components/playImage"
import SEO from "../components/seo"
import moment from "moment"

import '../components/main.css'

const IndexPage = () => {

  const [isPlaying,setIsPlaying] = useState(false)
  const [displayButton,setDisplayButton] = useState(true)
  const videoRef= useRef(null)

  useEffect(() => {
    //
    console.log("Sth happened")
    console.log(isPlaying)
    console.log(displayButton)
  },[displayButton,isPlaying]);

  const setPlayBack = () => {
    //setIsPlaying(false)
    //setDisplayButton(true)
  }

  const playVideo = (event) => {
    setIsPlaying(true)
    setDisplayButton(false)
    videoRef.current.playbackRate = 0.25
    videoRef.current.play()
  }

  const dateString = moment().format("YYYY-MM-DD")
  const year = moment().format("YYYY")

  return (
    <Layout>
      <SEO
        title="The famous Santorini sunset over Oia | Timelapse video"
        description="A timelapse video of the famous Santorini sunset over the city of Oia. As seen from the town of Imerovigli"
        image={"https://santoriniatsunset.com/_photos/rect.jpg"}
        schema={`
          {
            "@context": "https://schema.org/",
            "@type": "VideoObject",
            "name": "Santorini Oia sunset timelapse video",
            "description": "A timelapse video showing the sunset in Oia, Santorini - as seen from Imerovigli.",
            "thumbnailUrl": "https://santoriniatsunset.com/_photos/rect.jpg",
            "uploadDate": "`+dateString+`T06:00:00.000Z",
            "contentUrl": "https://santoriniatsunset.com/_video/sunset-in-oia-santorini-seen-from-imerovigli.mp4",
            "duration": "PT0M21S"
          }
       `}
        breadcrumbSchema={`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Santorini at sunset",
              "item": "https://santoriniatsunset.com"
            }]
          }
        `}
      />

      <video
        poster={VideoPoster}
        ref={videoRef}
        preload={"none"}
        onCanPlay={() => setPlayBack()}
        muted
        loop
        src={BackgroundVideo}
      >
        <source src={BackgroundVideo} type="video/mp4"/>
      </video>

      <div className={"wrapper"}>
        <h1>Santorini Oia sunset</h1>
        <h2>- as seen from <a href={"https://en.wikipedia.org/wiki/Imerovigli"}>Imerovigli</a></h2>
        <p>
          A timelapse video showing
          the famous <a href={"https://en.wikipedia.org/wiki/Sunset"}>sunset</a> over the
          city of <a href={"https://en.wikipedia.org/wiki/Oia,_Greece"}>Oia</a>,&nbsp;<a href={"https://en.wikipedia.org/wiki/Santorini"}>Santorini</a>.
        </p>
      </div>

      {displayButton &&
        <div className={"play"}>
          <span style={{}} tabIndex={0} role="button" aria-label="Play video" onClick={() => {playVideo();setDisplayButton(false)}}><PlayImage/></span>
        </div>
      }

      <div className={"cr"}>
        &copy; Copyright {year} <a href="https://twitter.com/freesurf">Knut Reistad</a>
      </div>
    </Layout>
  )
}

export default IndexPage
